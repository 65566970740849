<template>
  <c-box>
    <c-text class="label">
      {{ question }}
    </c-text>
    <c-flex
      flex-dir="column"
      pos="relative"
    >
      <c-flex
        pos="absolute"
        d="inline-flex"
        justify-content="space-between"
        z-index="1"
        top="0"
        left="0"
        w="full"
      >
        <button
          v-for="(i, index) in maxValue"
          :key="index"
          v-chakra
          type="button"
          h="20px"
          w="full"
          bg="lightgrey"
          border="1px solid #333"
          opacity="0.0"
          :cursor="isDisabled ? 'not-allowed' : 'pointer'"
          :disabled="isDisabled"
          @click.prevent="setProgress(index)"
        >
          &nbsp;
        </button>
      </c-flex>
      <c-box
        bg="linear-gradient(90deg, #F00 0%, #FFE600, #35a83a 100%)"
        w="100%"
        h="15px"
        pos="relative"
        border-radius="25px"
      >
        <c-box
          pos="absolute"
          top="50%"
          transform="translateY(-50%)"
          w="2px"
          h="20px"
          bg="white"
          left="30%"
        />
        <c-box
          pos="absolute"
          top="50%"
          transform="translateY(-50%)"
          w="2px"
          h="20px"
          bg="white"
          left="70%"
        />
        <c-flex
          pos="absolute"
          top="50%"
          transform="translate(-50%, -50%)"
          :left="`${valuePercentage}%`"
          h="20px"
          w="20px"
          color="black"
          border-radius="25px"
          bg="white"
          box-shadow="0px 0px 5px rgba(0, 0, 0, 0.25)"
          justify-content="center"
          align-items="center"
        >
          <c-text font-size="sm">
            {{ value }}
          </c-text>
        </c-flex>
      </c-box>
      <c-flex
        justify-content="space-between"
        mt="10px"
      >
        <c-text
          font-size="12px"
          font-weight="400"
          color="#555"
        >
          <slot name="minLabel">
            Belum terasa
          </slot>
        </c-text>
        <c-text
          font-size="12px"
          font-weight="400"
          color="#555"
        >
          <slot name="maxLabel">
            Sangat terasa
          </slot>
        </c-text>
      </c-flex>
    </c-flex>
  </c-box>
</template>

<script>
export default {
  name: 'QProgress',
  props: ['value', 'maxValue', 'question', 'isDisabled'],
  computed: {
    valuePercentage() {
      return (+this.value / this.maxValue) * 100
    },
  },
  methods: {
    setProgress(index) {
      // index = index * 1.25;
      // let value = (index / 5) * 100;
      let value = index + 1

      this.$emit('input', value)
    },
  },
}
</script>

<style scoped>
.label {
  font-size: 14px;
  padding-right: 12px;
  padding-bottom: 4px;
  opacity: 1;
  font-weight: 500;
  text-align: left;
  vertical-align: middle;
  display: inline-block;
  color: #555555;
}
</style>
