var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-text', {
    attrs: {
      "font-size": "2xl"
    }
  }, [_vm._v(" Antropometri (Ukuran & komposisi tubuh) ")]), _vm.data.weight ? _c('QShortAnswer', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "question": _vm.data.weight.question,
      "type": "number",
      "value": _vm.data.weight.answer,
      "is-disabled": _vm.isDisabled,
      "mt": "30px"
    },
    on: {
      "input": function input($event) {
        return _vm.setAnswer('weight', +$event);
      }
    },
    scopedSlots: _vm._u([{
      key: "rightAddon",
      fn: function fn() {
        return [_c('c-input-right-addon', [_vm._v("kg")])];
      },
      proxy: true
    }], null, false, 3225009822)
  }) : _vm._e(), _vm.data.waist ? _c('QShortAnswer', {
    attrs: {
      "question": _vm.data.waist.question,
      "is-disabled": _vm.isDisabled,
      "type": "number",
      "value": _vm.data.waist.answer
    },
    on: {
      "input": function input($event) {
        return _vm.setAnswer('waist', +$event);
      }
    },
    scopedSlots: _vm._u([{
      key: "rightAddon",
      fn: function fn() {
        return [_c('c-input-right-addon', [_vm._v("cm")])];
      },
      proxy: true
    }], null, false, 3145775452)
  }) : _vm._e(), _vm.data.changes ? _c('QProgress', {
    attrs: {
      "question": _vm.data.changes.question,
      "is-disabled": _vm.isDisabled,
      "value": _vm.data.changes.answer,
      "max-value": 10
    },
    on: {
      "input": function input($event) {
        return _vm.setAnswer('changes', +$event);
      }
    }
  }) : _vm._e(), _vm.data.fatPercentage ? _c('QShortAnswer', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "question": _vm.data.fatPercentage.question,
      "type": "number",
      "value": _vm.data.fatPercentage.answer,
      "is-disabled": _vm.isDisabled,
      "mt": "30px"
    },
    on: {
      "input": function input($event) {
        return _vm.setAnswer('fatPercentage', +$event);
      }
    },
    scopedSlots: _vm._u([{
      key: "rightAddon",
      fn: function fn() {
        return [_c('c-input-right-addon', [_vm._v("%")])];
      },
      proxy: true
    }], null, false, 1930322423)
  }) : _vm._e(), _vm.data.muscle ? _c('QShortAnswer', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "question": _vm.data.muscle.question,
      "type": "number",
      "value": _vm.data.muscle.answer,
      "is-disabled": _vm.isDisabled,
      "mt": "30px"
    },
    on: {
      "input": function input($event) {
        return _vm.setAnswer('muscle', +$event);
      }
    },
    scopedSlots: _vm._u([{
      key: "rightAddon",
      fn: function fn() {
        return [_c('c-input-right-addon', [_vm._v("kg")])];
      },
      proxy: true
    }], null, false, 3225009822)
  }) : _vm._e(), _vm.data.bodyWater ? _c('QShortAnswer', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "question": _vm.data.bodyWater.question,
      "type": "number",
      "value": _vm.data.bodyWater.answer,
      "is-disabled": _vm.isDisabled,
      "mt": "30px"
    },
    on: {
      "input": function input($event) {
        return _vm.setAnswer('bodyWater', +$event);
      }
    },
    scopedSlots: _vm._u([{
      key: "rightAddon",
      fn: function fn() {
        return [_c('c-input-right-addon', [_vm._v("%")])];
      },
      proxy: true
    }], null, false, 1930322423)
  }) : _vm._e(), _vm.data.visceralFat ? _c('QShortAnswer', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "question": _vm.data.visceralFat.question,
      "type": "number",
      "value": _vm.data.visceralFat.answer,
      "is-disabled": _vm.isDisabled,
      "mt": "30px"
    },
    on: {
      "input": function input($event) {
        return _vm.setAnswer('visceralFat', +$event);
      }
    }
  }) : _vm._e(), _vm.data.boneMass ? _c('QShortAnswer', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "question": _vm.data.boneMass.question,
      "type": "number",
      "value": _vm.data.boneMass.answer,
      "is-disabled": _vm.isDisabled,
      "mt": "30px"
    },
    on: {
      "input": function input($event) {
        return _vm.setAnswer('boneMass', +$event);
      }
    }
  }) : _vm._e(), _vm.data.basalMetabolicRate ? _c('QShortAnswer', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "question": _vm.data.basalMetabolicRate.question,
      "type": "number",
      "value": _vm.data.basalMetabolicRate.answer,
      "is-disabled": _vm.isDisabled,
      "mt": "30px"
    },
    on: {
      "input": function input($event) {
        return _vm.setAnswer('basalMetabolicRate', +$event);
      }
    }
  }) : _vm._e(), _vm._l(_vm.unhandledQuestions, function (item) {
    return [_c('pre', {
      key: item.id
    }, [_vm._v(_vm._s(item.id) + " " + _vm._s(item.question))])];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }