<template>
  <c-flex
    flex-dir="column"
  >
    <Steps
      :current="current"
      :total="total"
    />
    <c-box mt="20px">
      <c-box mt="20px">
        <slot />
      </c-box>
    </c-box>
    <c-flex
      mt="30px"
      w="full"
      justify-content="space-between"
    >
      <c-button
        rounded="full"
        variant-color="primary"
        :is-disabled="isPrev === false"
        @click="$emit('prev')"
      >
        <c-image
          :src="require('@/assets/icon-arrow-left-white.png')"
          w="24px"
          h="24px"
        />
        <slot name="prev">
          <c-text ml="10px">
            Kembali
          </c-text>
        </slot>
      </c-button>
      <c-button
        rounded="full"
        variant-color="primary"
        :is-disabled="isNext === false"
        @click="$emit('next')"
      >
        <slot name="next">
          <c-text>Selanjutnya</c-text>
        </slot>
        <c-image
          ml="10px"
          :src="require('@/assets/icon-arrow-right-white.png')"
          w="24px"
          h="24px"
        />
      </c-button>
    </c-flex>
  </c-flex>
</template>

<script>
import Steps from '@/components/steps.vue'
export default {
  name: 'CardStep',  
  components: { Steps },
  props: {
    current: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    isNext: Boolean,
    isPrev: Boolean,
  },
}
</script>
