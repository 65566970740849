var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-text', {
    attrs: {
      "font-size": "2xl"
    }
  }, [_vm._v(" Identitas ")]), _vm.data.namaDepan ? _c('QShortAnswer', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "question": _vm.data.namaDepan.question,
      "is-disabled": true,
      "value": _vm.data.namaDepan.answer,
      "mt": "30px"
    }
  }) : _vm._e(), _vm.data.namaBelakang ? _c('QShortAnswer', {
    attrs: {
      "question": _vm.data.namaBelakang.question,
      "is-disabled": true,
      "value": _vm.data.namaBelakang.answer
    }
  }) : _vm._e(), _vm.data.namaTengah ? _c('QShortAnswer', {
    attrs: {
      "question": _vm.data.namaTengah.question,
      "is-disabled": true,
      "value": _vm.data.namaTengah.answer
    }
  }) : _vm._e(), _vm.data.jenisKelamin ? _c('QShortAnswer', {
    attrs: {
      "question": _vm.data.jenisKelamin.question,
      "is-disabled": true,
      "value": _vm.data.jenisKelamin.answer
    }
  }) : _vm._e(), _vm.data.usia ? _c('QShortAnswer', {
    attrs: {
      "question": _vm.data.usia.question,
      "is-disabled": true,
      "value": _vm.data.usia.answer
    }
  }) : _vm._e(), _vm.data.tanggalLahir ? _c('QShortAnswer', {
    attrs: {
      "question": _vm.data.tanggalLahir.question,
      "is-disabled": true,
      "value": _vm.tanggalLahirFormatted
    }
  }) : _vm._e(), _vm.data.tinggiBadan ? _c('QShortAnswer', {
    attrs: {
      "question": _vm.data.tinggiBadan.question,
      "is-required": true,
      "is-disabled": _vm.isDisabled,
      "type": "number",
      "value": _vm.data.tinggiBadan.answer
    },
    on: {
      "input": function input($event) {
        return _vm.setAnswer('tinggiBadan', +$event);
      }
    }
  }) : _vm._e(), _vm._l(_vm.unhandledQuestions, function (item) {
    return [_c('pre', {
      key: item.id
    }, [_vm._v(_vm._s(item.id) + " " + _vm._s(item.question))])];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }