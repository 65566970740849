<template>
  <c-box>
    <c-text font-size="2xl">
      Identitas
    </c-text>

    <QShortAnswer
      v-if="data.namaDepan"
      v-chakra
      :question="data.namaDepan.question"
      :is-disabled="true"
      :value="data.namaDepan.answer"
      mt="30px"
    />
    <QShortAnswer
      v-if="data.namaBelakang"
      :question="data.namaBelakang.question"
      :is-disabled="true"
      :value="data.namaBelakang.answer"
    />
    <QShortAnswer
      v-if="data.namaTengah"
      :question="data.namaTengah.question"
      :is-disabled="true"
      :value="data.namaTengah.answer"
    />
    <QShortAnswer
      v-if="data.jenisKelamin"
      :question="data.jenisKelamin.question"
      :is-disabled="true"
      :value="data.jenisKelamin.answer"
    />
    <QShortAnswer
      v-if="data.usia"
      :question="data.usia.question"
      :is-disabled="true"
      :value="data.usia.answer"
    />
    <QShortAnswer
      v-if="data.tanggalLahir"
      :question="data.tanggalLahir.question"
      :is-disabled="true"
      :value="tanggalLahirFormatted"
    />
    <QShortAnswer
      v-if="data.tinggiBadan"
      :question="data.tinggiBadan.question"
      :is-required="true"
      :is-disabled="isDisabled"
      type="number"
      :value="data.tinggiBadan.answer"
      @input="setAnswer('tinggiBadan', +$event)"
    />

    <template v-for="item in unhandledQuestions">
      <pre :key="item.id">{{ item.id }} {{ item.question }}</pre>
    </template>
  </c-box>
</template>



<script >
import _ from 'lodash';
import dayjs from 'dayjs';
import QShortAnswer from '@/components/quizionary/short-answer.vue';
import { computed, watch } from '@vue/composition-api';
import { getSubmittedValue, useEqualWatch, useQuestion } from './helper-mixin';
const __sfc_main = {
  name: 'IdentitasProgressTracker'
};
__sfc_main.props = ['profile', 'value', 'disabled'];

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const profile = computed(() => props.profile);
  const questions = computed(() => props.value);
  const isDisabled = computed(() => props.disabled ?? false);
  const mapped = {
    1: 'namaDepan',
    2: 'namaBelakang',
    3: 'namaTengah',
    4: 'jenisKelamin',
    5: 'usia',
    6: 'tanggalLahir',
    7: 'tinggiBadan',
    '5daea1c8-7b16-469e-b257-75ca1037b4c4': 'namaDepan',
    'd3a6adc8-6ce5-4df9-8671-50543ffcd1da': 'namaBelakang',
    '050f6849-d2c1-40c0-a1ff-5a653d1202e7': 'namaTengah',
    'd7262c23-b8d5-439e-80a1-e2a43156ecee': 'jenisKelamin',
    '9d03138a-4dfd-4faa-bba1-ea599459745e': 'usia',
    '23318125-3425-499c-9397-f274b781fccb': 'tanggalLahir',
    'd19ac4ff-7e4a-4d48-949e-c767f2a8c667': 'tinggiBadan'
  }; // const data = ref({})
  // watch(questions, (questions) => {
  //   data.value = getQuestion(questions, mapped)
  // })
  // watchEffect(() => {
  //   let value = getQuestion(questions.value, mapped)
  //
  //   if (!equal(data.value, value)) {
  //     data.value = value;
  //   }
  // })

  const data = useQuestion(questions, mapped);
  const unhandledQuestions = computed(() => data.value.unhandledQuestions);
  let tanggalLahirFormatted = computed(() => {
    return dayjs(data.value.tanggalLahir?.answer).format('DD MMMM YYYY');
  });
  watch([profile, questions], ([profile, questions]) => {
    if (profile == null || questions == null) return;
    data.value.namaDepan.answer = profile.firstName;
    data.value.namaBelakang.answer = profile.lastName; // data.value.namaTengah.answer = profile.middleName

    data.value.jenisKelamin.answer = profile.gender === 'male' ? 'Laki-laki' : 'Perempuan';
    data.value.tanggalLahir.answer = profile.birthDate;
    let lahir = dayjs(data.value.tanggalLahir?.answer);
    data.value.usia.answer = dayjs().diff(lahir, 'year');
  }, {
    immediate: true
  });
  let submittedData = computed(() => {
    let items = getSubmittedValue(data.value);
    let result = items.filter(it => !Array.isArray(it));
    return result;
  });
  useEqualWatch(submittedData, data => {
    emit('input', data);
  });

  function setAnswer(name, value) {
    let val = _.cloneDeep(data.value);

    _.set(val, `${name}.answer`, value);

    data.value = val;
  }

  return {
    isDisabled,
    data,
    unhandledQuestions,
    tanggalLahirFormatted,
    setAnswer
  };
};

__sfc_main.components = Object.assign({
  QShortAnswer
}, __sfc_main.components);
export default __sfc_main;
</script>
