var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('HeaderComp'), _c('c-box', {
    attrs: {
      "max-w": "1200",
      "mx": "auto",
      "mt": "30px"
    }
  }, [_c('Breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _c('hr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "mt": "20px"
    }
  }), _c('c-flex', {
    attrs: {
      "w": "full",
      "my": "30px",
      "flex-dir": "column",
      "align-items": "center"
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "grid-gap": "70px"
    }
  }, [_c('BrandButton', [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-chevron-left.svg')
    }
  })], 1), _c('c-text', {
    attrs: {
      "font-size": "2xl"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("formatDate")(_vm.date, "DD MMM YYYY")) + " ")]), _c('BrandButton', [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-chevron-right.svg')
    }
  })], 1)], 1), _c('c-box', {
    attrs: {
      "w": "600px",
      "max-w": "600px",
      "mt": "80px"
    }
  }, [_c('CardStep', {
    attrs: {
      "current": _vm.currentStep,
      "total": _vm.totalQuestions,
      "is-next": _vm.currentStep < _vm.totalStep,
      "is-prev": _vm.currentStep !== 1
    },
    on: {
      "next": function next($event) {
        return _vm.onNext();
      },
      "prev": function prev($event) {
        _vm.currentStep -= 1;
      }
    },
    scopedSlots: _vm._u([{
      key: "next",
      fn: function fn() {
        return [_vm.currentStep === _vm.totalQuestions ? [_vm._v(" Submit ")] : [_vm._v(" Selanjutnya ")]];
      },
      proxy: true
    }])
  }, [_vm.currentStep === 1 ? _c('FormIdentitas', {
    attrs: {
      "profile": _vm.profile
    },
    model: {
      value: _vm.questions.identitas,
      callback: function callback($$v) {
        _vm.$set(_vm.questions, "identitas", $$v);
      },
      expression: "questions.identitas"
    }
  }) : _vm._e(), _vm.currentStep === 2 ? _c('FormAntrometri', {
    attrs: {
      "profile": _vm.profile
    },
    model: {
      value: _vm.questions.antropometri,
      callback: function callback($$v) {
        _vm.$set(_vm.questions, "antropometri", $$v);
      },
      expression: "questions.antropometri"
    }
  }) : _vm._e(), _vm.currentStep === 3 ? _c('FormPolaMakan', {
    attrs: {
      "profile": _vm.profile
    },
    model: {
      value: _vm.questions.pola_makan,
      callback: function callback($$v) {
        _vm.$set(_vm.questions, "pola_makan", $$v);
      },
      expression: "questions.pola_makan"
    }
  }) : _vm._e(), _vm.currentStep === 4 ? _c('FormPerasaanPengetahuan', {
    attrs: {
      "profile": _vm.profile
    },
    model: {
      value: _vm.questions.perasaan_pengetahuan,
      callback: function callback($$v) {
        _vm.$set(_vm.questions, "perasaan_pengetahuan", $$v);
      },
      expression: "questions.perasaan_pengetahuan"
    }
  }) : _vm._e(), _vm.currentStep === 5 ? _c('FormAktifitasHarian', {
    attrs: {
      "profile": _vm.profile
    },
    model: {
      value: _vm.questions.aktivitas_harian,
      callback: function callback($$v) {
        _vm.$set(_vm.questions, "aktivitas_harian", $$v);
      },
      expression: "questions.aktivitas_harian"
    }
  }) : _vm._e(), _vm.currentStep === 6 ? _c('FormBiokimia', {
    attrs: {
      "profile": _vm.profile
    },
    model: {
      value: _vm.questions.prosedur_medis,
      callback: function callback($$v) {
        _vm.$set(_vm.questions, "prosedur_medis", $$v);
      },
      expression: "questions.prosedur_medis"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1), _c('FooterComp')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }