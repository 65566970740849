import { render, staticRenderFns } from "./short-answer.vue?vue&type=template&id=b5765832&scoped=true&"
import script from "./short-answer.vue?vue&type=script&lang=js&"
export * from "./short-answer.vue?vue&type=script&lang=js&"
import style0 from "./short-answer.vue?vue&type=style&index=0&id=b5765832&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5765832",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CFormLabel: require('@chakra-ui/vue').CFormLabel, CInput: require('@chakra-ui/vue').CInput, CInputGroup: require('@chakra-ui/vue').CInputGroup, CFormHelperText: require('@chakra-ui/vue').CFormHelperText, CFormControl: require('@chakra-ui/vue').CFormControl})
