var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-text', {
    staticClass: "label"
  }, [_vm._v(" " + _vm._s(_vm.question) + " ")]), _c('c-flex', {
    attrs: {
      "flex-dir": "column",
      "pos": "relative"
    }
  }, [_c('c-flex', {
    attrs: {
      "pos": "absolute",
      "d": "inline-flex",
      "justify-content": "space-between",
      "z-index": "1",
      "top": "0",
      "left": "0",
      "w": "full"
    }
  }, _vm._l(_vm.maxValue, function (i, index) {
    return _c('button', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      key: index,
      attrs: {
        "type": "button",
        "h": "20px",
        "w": "full",
        "bg": "lightgrey",
        "border": "1px solid #333",
        "opacity": "0.0",
        "cursor": _vm.isDisabled ? 'not-allowed' : 'pointer',
        "disabled": _vm.isDisabled
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.setProgress(index);
        }
      }
    }, [_vm._v("   ")]);
  }), 0), _c('c-box', {
    attrs: {
      "bg": "linear-gradient(90deg, #F00 0%, #FFE600, #35a83a 100%)",
      "w": "100%",
      "h": "15px",
      "pos": "relative",
      "border-radius": "25px"
    }
  }, [_c('c-box', {
    attrs: {
      "pos": "absolute",
      "top": "50%",
      "transform": "translateY(-50%)",
      "w": "2px",
      "h": "20px",
      "bg": "white",
      "left": "30%"
    }
  }), _c('c-box', {
    attrs: {
      "pos": "absolute",
      "top": "50%",
      "transform": "translateY(-50%)",
      "w": "2px",
      "h": "20px",
      "bg": "white",
      "left": "70%"
    }
  }), _c('c-flex', {
    attrs: {
      "pos": "absolute",
      "top": "50%",
      "transform": "translate(-50%, -50%)",
      "left": "".concat(_vm.valuePercentage, "%"),
      "h": "20px",
      "w": "20px",
      "color": "black",
      "border-radius": "25px",
      "bg": "white",
      "box-shadow": "0px 0px 5px rgba(0, 0, 0, 0.25)",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "sm"
    }
  }, [_vm._v(" " + _vm._s(_vm.value) + " ")])], 1)], 1), _c('c-flex', {
    attrs: {
      "justify-content": "space-between",
      "mt": "10px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "12px",
      "font-weight": "400",
      "color": "#555"
    }
  }, [_vm._t("minLabel", function () {
    return [_vm._v(" Belum terasa ")];
  })], 2), _c('c-text', {
    attrs: {
      "font-size": "12px",
      "font-weight": "400",
      "color": "#555"
    }
  }, [_vm._t("maxLabel", function () {
    return [_vm._v(" Sangat terasa ")];
  })], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }