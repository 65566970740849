var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-button', _vm._g(_vm._b({
    attrs: {
      "type": "button",
      "variant-color": "primary",
      "rounded": "full",
      "h": "22px",
      "w": "22px",
      "min-w": "0",
      "m": "0",
      "p": "0"
    }
  }, 'c-button', _vm.$attrs, false), _vm.$listeners), [_vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }