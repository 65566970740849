<template>
  <c-form-control
    mb="30px"
    :is-required="isRequired"
    :is-invalid="$v.value.$invalid"
  >
    <c-form-label
      font-size="14px"
      color="#555555"
      font-weigh="400"
      font-family="Roboto"
    >
      {{ question }}
    </c-form-label>

    <c-select
      v-model="value__"
      :placeholder="question"
      height="62px"
      :is-disabled="isDisabled"
      :disabled="isDisabled"
    >
      <option
        v-for="selection in selections"
        :key="selection.id"
        :value="selection.answer"
      >
        {{ selection.answer }}
      </option>
    </c-select>
    <c-form-helper-text font-size="12px">
      {{ subtext }}
    </c-form-helper-text>
  </c-form-control>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'QAnswerDropdown',
  props: ['question', 'isRequired', 'selections', 'value', 'subtext', 'isDisabled'],
  validations: {
    value: {
      required: requiredIf(function() {
        return this.isRequired
      }),
    },
  },
  computed: {
    value__: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
}
</script>
