var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-form-control', {
    attrs: {
      "mb": "30px",
      "is-required": _vm.isRequired,
      "is-invalid": _vm.$v.value.$invalid
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" " + _vm._s(_vm.question) + " ")]), _c('c-radio-group', {
    attrs: {
      "required": _vm.isRequired,
      "is-required": _vm.isRequired
    },
    model: {
      value: _vm.value__,
      callback: function callback($$v) {
        _vm.value__ = $$v;
      },
      expression: "value__"
    }
  }, _vm._l(_vm.selections, function (item) {
    return _c('c-radio', {
      key: item.id,
      attrs: {
        "size": "lg",
        "value": item.answer,
        "disabled": _vm.isDisabled,
        "is-disabled": _vm.isDisabled
      }
    }, [_vm._v(" " + _vm._s(item.answer) + " ")]);
  }), 1), _c('c-form-helper-text', {
    attrs: {
      "font-size": "12px"
    }
  }, [_vm._v(" " + _vm._s(_vm.subtext) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }