<template>
  <c-button
    type="button"
    variant-color="primary"
    rounded="full"
    h="22px"
    w="22px"
    min-w="0"
    m="0"
    p="0"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </c-button>
</template>

<script>
export default {
  name: 'BrandButton',
}
</script>
