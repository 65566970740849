var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-text', {
    attrs: {
      "font-size": "2xl"
    }
  }, [_vm._v(" Perasaan & Pengetahuan ")]), _vm.data.perasaan ? _c('QProgress', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "max-value": 5,
      "question": _vm.data.perasaan.question,
      "is-disabled": _vm.isDisabled,
      "value": _vm.data.perasaan.answer,
      "mt": "30px"
    },
    on: {
      "input": function input($event) {
        return _vm.setAnswer('perasaan', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "minLabel",
      fn: function fn() {
        return [_vm._v(" Kurang semangat ")];
      },
      proxy: true
    }, {
      key: "maxLabel",
      fn: function fn() {
        return [_vm._v(" Sangat semangat ")];
      },
      proxy: true
    }], null, false, 1242914365)
  }) : _vm._e(), _vm.data.dipelajari ? _c('QParagraph', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "question": _vm.data.dipelajari.question,
      "value": _vm.data.dipelajari.answer,
      "is-disabled": _vm.isDisabled,
      "mt": "30px"
    },
    on: {
      "input": function input($event) {
        return _vm.setAnswer('dipelajari', $event);
      }
    }
  }) : _vm._e(), _vm.data.fotoProgress ? _c('QFileAnswer', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "is-uploading": _vm.isUploading,
      "question": _vm.data.fotoProgress.question,
      "subtext": "Foto maximal 3MB",
      "value": _vm.data.fotoProgress.answer,
      "mt": "30px",
      "is-disabled": _vm.isDisabled
    },
    on: {
      "input": function input($event) {
        return _vm.setAnswer('fotoProgress', $event);
      },
      "upload": function upload($event) {
        return _vm.onUpload($event);
      }
    }
  }) : _vm._e(), _vm._l(_vm.unhandledQuestions, function (item) {
    return [_c('pre', {
      key: item.id
    }, [_vm._v(_vm._s(item.id) + " " + _vm._s(item.question))])];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }