<template>
  <c-form-control>
    <c-form-label
      font-size="14px"
      color="#555555"
      font-weigh="400"
      font-family="Roboto"
    >
      {{ question }}
    </c-form-label>
    <c-flex>
      <!--Show spinner-->
      <c-flex
        v-if="isUploading"
        flex-dir="column"
        flex-shrink="0"
        border="1px solid #C4C4C4"
        h="180px"
        w="180px"
        rounded="lg"
        justify-content="center"
        align-items="center"
      >
        <div class="lds-roller">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
        <c-text
          mt="20px"
          color="gray.900"
        >
          Uploading...
        </c-text>
      </c-flex>
      <c-form-label
        v-show="!isUploading"
        border="1px solid #C4C4C4"
        for="photo"
        width="180px"
        height="180px"
        border-radius="5px"
        overflow="hidden"
        :cursor="isDisabled ? 'not-allowed' : 'pointer'"
        :background-image="
          photoUrl == null ? 'url(/images/empty-photo.png)' : photoUrl
        "
        :background-size="photoUrl == null ? '63px 63px' : 'cover'"
        background-position="center center"
        background-repeat="no-repeat"
      >
        <input
          id="photo"
          v-chakra
          type="file"
          name="photoSnack"
          opacity="0"
          display="none"
          :disabled="isDisabled"
          @change="onChange"
        >
      </c-form-label>
      <c-flex
        ml="30px"
        width="70%"
        justify-content="flex-start"
        align-items="center"
      >
        <c-text
          v-if="subtext"
          color="gray.900"
          font-size="16px"
          line-height="24px"
          fonr-family="Roboto"
        >
          {{ subtext }}
        </c-text>
      </c-flex>
    </c-flex>
  </c-form-control>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'QFileAnswer',
  props: ['value', 'question', 'subtext', 'isUploading', 'isDisabled'],
  data() {
    return {
      snack: null,
      photoUrl: null,
      photoFile: null,
    }
  },
  validations: {
    photoUrl: {},
    snack: { required: requiredIf((v) => v.photoUrl == null) },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val == null) return

        if (this.photoUrl != null && this.photoUrl.startsWith('blob')) {
          URL.revokeObjectURL(this.photoUrl)
        }

        this.photoUrl = val
      },
    },
    async photoFile(val) {
      if (val == null) return

      if (this.photoUrl != null && this.photoUrl.startsWith('blob')) {
        URL.revokeObjectURL(this.photoUrl)
      }

      this.photoUrl = URL.createObjectURL(val)
      this.$emit('upload', val)
      // URL.revokeObjectURL(this.photoUrl)
    },
  },
  methods: {
    onChange(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return

      this.photoFile = files[0]
    },
  },
}
</script>

<style scoped>
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #008C81;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
