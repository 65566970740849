var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-text', {
    attrs: {
      "font-size": "2xl"
    }
  }, [_vm._v(" Data Biokimia, Pengecekan & Prosedur Medis ")]), _vm.data.gulaDarahSewaktu ? _c('QShortAnswer', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "question": _vm.data.gulaDarahSewaktu.question,
      "is-disabled": _vm.isDisabled,
      "type": "number",
      "value": _vm.data.gulaDarahSewaktu.answer,
      "mt": "30px"
    },
    on: {
      "input": function input($event) {
        return _vm.setAnswer('gulaDarahSewaktu', $event);
      }
    }
  }) : _vm._e(), _vm.data.kolesterolTotal ? _c('QShortAnswer', {
    attrs: {
      "question": _vm.data.kolesterolTotal.question,
      "is-disabled": _vm.isDisabled,
      "type": "number",
      "value": _vm.data.kolesterolTotal.answer
    },
    on: {
      "input": function input($event) {
        return _vm.setAnswer('kolesterolTotal', $event);
      }
    }
  }) : _vm._e(), _vm.data.kolesterolHdl ? _c('QShortAnswer', {
    attrs: {
      "question": _vm.data.kolesterolHdl.question,
      "is-disabled": _vm.isDisabled,
      "type": "number",
      "value": _vm.data.kolesterolHdl.answer
    },
    on: {
      "input": function input($event) {
        return _vm.setAnswer('kolesterolHdl', $event);
      }
    }
  }) : _vm._e(), _vm.data.kolesterolLdl ? _c('QShortAnswer', {
    attrs: {
      "question": _vm.data.kolesterolLdl.question,
      "is-disabled": _vm.isDisabled,
      "type": "number",
      "value": _vm.data.kolesterolLdl.answer
    },
    on: {
      "input": function input($event) {
        return _vm.setAnswer('kolesterolLdl', $event);
      }
    }
  }) : _vm._e(), _vm.data.asamUrat ? _c('QShortAnswer', {
    attrs: {
      "question": _vm.data.asamUrat.question,
      "is-disabled": _vm.isDisabled,
      "type": "number",
      "value": _vm.data.asamUrat.answer
    },
    on: {
      "input": function input($event) {
        return _vm.setAnswer('asamUrat', $event);
      }
    }
  }) : _vm._e(), _vm.data.lain ? _c('QShortAnswer', {
    attrs: {
      "question": _vm.data.lain.question,
      "is-disabled": _vm.isDisabled,
      "value": _vm.data.lain.answer
    },
    on: {
      "input": function input($event) {
        return _vm.setAnswer('lain', $event);
      }
    }
  }) : _vm._e(), _vm._l(_vm.unhandledQuestions, function (item) {
    return [_c('pre', {
      key: item.id
    }, [_vm._v(_vm._s(item.id) + " " + _vm._s(item.question))])];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }