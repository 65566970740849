<template>
  <c-box>
    <HeaderComp />

    <c-box
      max-w="1200"
      mx="auto"
      mt="30px"
    >
      <Breadcrumb :items="breadcrumbItems" />
      <hr
        v-chakra
        mt="20px"
      >

      <c-flex
        w="full"
        my="30px"
        flex-dir="column"
        align-items="center"
      >
        <c-flex
          align-items="center"
          grid-gap="70px"
        >
          <BrandButton>
            <c-image :src="require('@/assets/ic-chevron-left.svg')" />
          </BrandButton>
          <c-text font-size="2xl">
            {{ date | formatDate("DD MMM YYYY") }}
          </c-text>
          <BrandButton>
            <c-image :src="require('@/assets/ic-chevron-right.svg')" />
          </BrandButton>
        </c-flex>

        <c-box
          w="600px"
          max-w="600px"
          mt="80px"
        >
          <CardStep
            :current="currentStep"
            :total="totalQuestions"
            :is-next="currentStep < totalStep"
            :is-prev="currentStep !== 1"
            @next="onNext()"
            @prev="currentStep -= 1"
          >
            <FormIdentitas
              v-if="currentStep === 1"
              v-model="questions.identitas"
              :profile="profile"
            />
            <FormAntrometri
              v-if="currentStep === 2"
              v-model="questions.antropometri"
              :profile="profile"
            />
            <FormPolaMakan
              v-if="currentStep === 3"
              v-model="questions.pola_makan"
              :profile="profile"
            />
            <FormPerasaanPengetahuan
              v-if="currentStep === 4"
              v-model="questions.perasaan_pengetahuan"
              :profile="profile"
            />
            <FormAktifitasHarian
              v-if="currentStep === 5"
              v-model="questions.aktivitas_harian"
              :profile="profile"
            />
            <FormBiokimia
              v-if="currentStep === 6"
              v-model="questions.prosedur_medis"
              :profile="profile"
            />

            <template #next>
              <template v-if="currentStep === totalQuestions">
                Submit
              </template>
              <template v-else>
                Selanjutnya
              </template>
            </template>
          </CardStep>
        </c-box>
      </c-flex>
    </c-box>

    <FooterComp />
  </c-box>
</template>

<script>
import HeaderComp from '@/components/header/index.vue'
import Breadcrumb from '@/components/breadcrumb'
import FooterComp from '@/components/layouts/footer/index.vue'
import BrandButton from '@/components/brand-button'
import CardStep from '@/views/nutritionists/progress-tracker/master/card-step'
import _ from 'lodash'
import FormIdentitas from './forms/identitas.vue'
import FormAntrometri from './forms/antropometri.vue'
import FormPolaMakan from '@/views/client/progress-tracker/forms/pola-makan'
import FormPerasaanPengetahuan from '@/views/client/progress-tracker/forms/perasaan-pengetahuan'
import FormAktifitasHarian from '@/views/client/progress-tracker/forms/aktifitas-harian'
import FormBiokimia from '@/views/client/progress-tracker/forms/biokimia'
import equal from 'fast-deep-equal'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
window.dayjs = dayjs

export default {
  name: 'ClientProgressSubmission',
  components: {
    FormBiokimia,
    FormAktifitasHarian,
    FormPerasaanPengetahuan,
    FormPolaMakan,
    CardStep,
    BrandButton,
    HeaderComp,
    Breadcrumb,
    FooterComp,
    FormIdentitas,
    FormAntrometri,
  },
  data() {
    return {
      totalStep: 8,
      allQuestions: [],
      questions: {
        identitas: [],
      },
      profile: null,
      selectedProgramId: null,
      progressTrackerId: null,
    }
  },
  computed: {
    totalQuestions() {
      return Object.keys(this.questions ?? 5).length
    },
    currentStep: {
      get() {
        return +(this.$route.query.step ?? 1)
      },
      set(v) {
        this.$router.push({ query: { step: v } })
      },
    },
    date() {
      return this.$route.params.date
    },
    breadcrumbItems() {
      return [
        { label: 'Beranda', isCurrent: false, href: '/' },
        { label: 'Progress & Tracker', isCurrent: true },
      ]
    },
    answers() {
      return Object.values(this.questions).flatMap(it => it).map((it) => {
        return {
          category: it.category,
          ptQuestionId: it.id,
          question: it.question,
          answer: it.answer,
        }
      })
    },
  },
  watch: {
    questions: {
      deep: true,
      handler(questions, old) {
        if (equal(questions, old)) return

        window.questions = JSON.parse(JSON.stringify(questions))
        window.questions_ = (item = null) => {
          let items = questions[item]
          if (items != null) {
            console.log(JSON.stringify(items, null, 2))
          } else {
            console.log(JSON.stringify(questions, null, 2))
          }
        }
      },
    },
  },
  async mounted() {
    window.getQuestions = this.getQuestions
    window.printAnswers = this.printAnswers
    window.submit = this.submit

    let [questionsData, profile] = await Promise.all([
      this.$store.dispatch('clientProgressTracker/getQuestions'),
      this.$store.dispatch('profile/getProfileClient'),
    ])

    this.profile = profile

    let questions = questionsData.questions.filter(it => it.checked)
    questions = _.groupBy(questions, 'category')

    this.totalstep = questions.length
    this.allQuestions = questions
    this.questions = questions
    this.selectedProgramId = questionsData.programId
    this.progressTrackerId = questionsData.id
  },
  methods: {
    printAnswers(category) {
      if (category != null) {
        let answers = this.answers.filter(it => it.category === category)
        console.log(JSON.stringify(answers, null, 2))
      } else {
        console.log(JSON.stringify(this.answers, null, 2))
      }
    },
    getQuestions(name) {
      if (name != null) {
        return this.questions[name]
      }
      return this.questions
    },
    onNext() {
      if (this.currentStep < this.totalQuestions) {
        this.currentStep++
      } else {
        this.submit()
      }
    },
    async submit() {
      let answers = this.answers.map(it => _.omit(it, 'category'))
      let payload = {
        ptMasterId: this.progressTrackerId,
        date: dayjs.utc(this.date).format('YYYY-MM-DD HH:mm:ss'),
        answers,
      }

      // let nullAnswers = answers.filter(it => it.answer == null && it.ptQuestionId !== '3')
      // if (nullAnswers.length > 0) {
      //   return;
      // }

      try {
        await this.$store.dispatch('clientProgressTracker/submission', payload)
        await this.$router.replace({ name: 'client.progress-tracker.success' })
      } catch (e) {
        this.$errorToastFromCatch(e)
      }
    },
  },
}
</script>
