var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-form-control', [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" " + _vm._s(_vm.question) + " ")]), _c('c-flex', [_vm.isUploading ? _c('c-flex', {
    attrs: {
      "flex-dir": "column",
      "flex-shrink": "0",
      "border": "1px solid #C4C4C4",
      "h": "180px",
      "w": "180px",
      "rounded": "lg",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('div', {
    staticClass: "lds-roller"
  }, [_c('div'), _c('div'), _c('div'), _c('div'), _c('div'), _c('div'), _c('div'), _c('div')]), _c('c-text', {
    attrs: {
      "mt": "20px",
      "color": "gray.900"
    }
  }, [_vm._v(" Uploading... ")])], 1) : _vm._e(), _c('c-form-label', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isUploading,
      expression: "!isUploading"
    }],
    attrs: {
      "border": "1px solid #C4C4C4",
      "for": "photo",
      "width": "180px",
      "height": "180px",
      "border-radius": "5px",
      "overflow": "hidden",
      "cursor": _vm.isDisabled ? 'not-allowed' : 'pointer',
      "background-image": _vm.photoUrl == null ? 'url(/images/empty-photo.png)' : _vm.photoUrl,
      "background-size": _vm.photoUrl == null ? '63px 63px' : 'cover',
      "background-position": "center center",
      "background-repeat": "no-repeat"
    }
  }, [_c('input', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "id": "photo",
      "type": "file",
      "name": "photoSnack",
      "opacity": "0",
      "display": "none",
      "disabled": _vm.isDisabled
    },
    on: {
      "change": _vm.onChange
    }
  })]), _c('c-flex', {
    attrs: {
      "ml": "30px",
      "width": "70%",
      "justify-content": "flex-start",
      "align-items": "center"
    }
  }, [_vm.subtext ? _c('c-text', {
    attrs: {
      "color": "gray.900",
      "font-size": "16px",
      "line-height": "24px",
      "fonr-family": "Roboto"
    }
  }, [_vm._v(" " + _vm._s(_vm.subtext) + " ")]) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }