var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-text', {
    attrs: {
      "font-size": "2xl"
    }
  }, [_vm._v(" Aktivitas Harian ")]), _vm.data.jenisOlahraga ? _c('c-form-control', {
    attrs: {
      "mt": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "16px",
      "color": "#555555",
      "font-weight": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Jenis olahraga yang dilakukan ")]), _c('c-checkbox-group', {
    attrs: {
      "size": "lg",
      "variant-color": "green",
      "spacing": "2",
      "required": ""
    },
    model: {
      value: _vm.sportType_,
      callback: function callback($$v) {
        _vm.sportType_ = $$v;
      },
      expression: "sportType_"
    }
  }, [_vm._l(_vm.sportTypeList_, function (item) {
    return [_c('c-checkbox', {
      key: item.type,
      attrs: {
        "value": item.type,
        "is-disabled": _vm.isDisabled || _vm.sportType_.includes('Tidak ada') && item.type !== 'Tidak ada'
      }
    }, [_vm._v(" " + _vm._s(item.type) + " ")]), _vm.sportType_.includes(item.type) && item.type !== 'Tidak ada' ? _c('c-form-control', {
      key: "duration-".concat(item.type)
    }, [_c('c-form-label', {
      attrs: {
        "font-size": "16px",
        "color": "#555555",
        "font-weight": "400",
        "font-family": "Roboto"
      }
    }, [_vm._v(" Durasi ")]), _c('c-select', {
      attrs: {
        "height": "62px",
        "placeholder": "Duration",
        "required": ""
      },
      model: {
        value: _vm.durations[item.type],
        callback: function callback($$v) {
          _vm.$set(_vm.durations, item.type, $$v);
        },
        expression: "durations[item.type]"
      }
    }, [_c('option', {
      attrs: {
        "value": "Tidak melakukan atau kurang dari 10 menit"
      }
    }, [_vm._v(" Tidak melakukan atau kurang dari 10 menit ")]), _c('option', {
      attrs: {
        "value": "10 - 15 menit"
      }
    }, [_vm._v(" 10 - 15 menit ")]), _c('option', {
      attrs: {
        "value": "16 - 30 menit"
      }
    }, [_vm._v(" 16 - 30 menit ")]), _c('option', {
      attrs: {
        "value": "31 - 45 menit"
      }
    }, [_vm._v(" 31 - 45 menit ")]), _c('option', {
      attrs: {
        "value": "46 - 60 menit"
      }
    }, [_vm._v(" 46 - 60 menit ")]), _c('option', {
      attrs: {
        "value": "Lebih dari 60 menit"
      }
    }, [_vm._v(" Lebih dari 60 menit ")])])], 1) : _vm._e()];
  })], 2)], 1) : _vm._e(), _vm.data.aktifWeekdays ? _c('QDropdown', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "selections": [{
        id: '1',
        answer: 'Dominan berbaring'
      }, {
        id: '2',
        answer: 'Dominan duduk'
      }, {
        id: '3',
        answer: 'Banyak berdiri'
      }, {
        id: '4',
        answer: 'Banyak jalan kaki'
      }],
      "question": _vm.data.aktifWeekdays.question,
      "value": _vm.data.aktifWeekdays.answer,
      "mt": "30px",
      "is-disabled": _vm.isDisabled
    },
    on: {
      "input": function input($event) {
        return _vm.setAnswer('aktifWeekdays', $event);
      }
    }
  }) : _vm._e(), _vm.data.aktifWeekend ? _c('QDropdown', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "selections": [{
        id: '1',
        answer: 'Dominan berbaring'
      }, {
        id: '2',
        answer: 'Dominan duduk'
      }, {
        id: '3',
        answer: 'Banyak berdiri'
      }, {
        id: '4',
        answer: 'Banyak jalan kaki'
      }],
      "question": _vm.data.aktifWeekend.question,
      "value": _vm.data.aktifWeekend.answer,
      "mt": "30px",
      "is-disabled": _vm.isDisabled
    },
    on: {
      "input": function input($event) {
        return _vm.setAnswer('aktifWeekend', $event);
      }
    }
  }) : _vm._e(), _vm._l(_vm.unhandledQuestions, function (item) {
    return [_c('pre', {
      key: item.id
    }, [_vm._v(_vm._s(item.id) + " " + _vm._s(item.question))])];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }