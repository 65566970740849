<template>
  <c-form-control
    mb="30px"
    :is-required="isRequired"
    :is-invalid="$v.value.$invalid"
  >
    <c-form-label
      font-size="14px"
      color="#555555"
      font-weigh="400"
      font-family="Roboto"
    >
      {{ question }}
    </c-form-label>
    <c-input-group size="lg">
      <slot name="leftAddon" />
      <c-input
        v-chakra="{
          ':first-child': {
            roundedLeft: 'md',
          },
          ':last-child': {
            roundedRight: 'md',
          },
        }"
        :placeholder="question"
        :type="formType"
        :value="value"
        :disabled="isDisabled"
        rounded="0"
        @input="$emit('input', $event)"
      />
      <slot name="rightAddon" />
    </c-input-group>
    <c-form-helper-text font-size="12px">
      {{ subtext }}
    </c-form-helper-text>
  </c-form-control>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'QShortAnswer',
  props: ['question', 'isRequired', 'type', 'value', 'subtext', 'isDisabled'],
  computed: {
    formType() {
      return this.type ?? 'text'
    },
  },
  validations: {
    value: {
      required: requiredIf(function() {
        return this.isRequired
      }),
    },
  },
}
</script>

<style scoped>
input[disabled] {
  background-color: #f2f2f2;
  opacity: initial;
  border-color: #c4c4c4;
}
[data-chakra-component="CInputRightAddon"] ~ input {
  border: 2px solid red;
}

[data-chakra-component="CInputGroup"] input {
}
[data-chakra-component="CInputGroup"] input:first-child {
}
</style>
