var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-form-control', {
    attrs: {
      "mb": "30px",
      "is-required": _vm.isRequired,
      "is-invalid": _vm.$v.value.$invalid
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" " + _vm._s(_vm.question) + " ")]), _c('textarea', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    class: {
      invalid: _vm.$v.value.$invalid
    },
    attrs: {
      "id": "story",
      "rows": "3",
      "placeholder": _vm.subtext,
      "d": "block",
      "border": "1px solid #E2E8F0",
      "border-radius": "md",
      "minHeight": "150px",
      "type": "text",
      "lineHeight": "20px",
      "p": "10px",
      "w": "100%",
      "required": _vm.isRequired,
      "disabled": _vm.isDisabled
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', $event.target.value);
      }
    }
  }), _vm._v(" "), _c('c-form-helper-text', {
    attrs: {
      "font-size": "12px"
    }
  }, [_vm._v(" " + _vm._s(_vm.subtext) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }