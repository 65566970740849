<template>
  <c-box>
    <c-text font-size="2xl">
      Data Biokimia, Pengecekan & Prosedur Medis
    </c-text>

    <QShortAnswer
      v-if="data.gulaDarahSewaktu"
      v-chakra
      :question="data.gulaDarahSewaktu.question"
      :is-disabled="isDisabled"
      type="number"
      :value="data.gulaDarahSewaktu.answer"
      mt="30px"
      @input="setAnswer('gulaDarahSewaktu', $event)"
    />
    <QShortAnswer
      v-if="data.kolesterolTotal"
      :question="data.kolesterolTotal.question"
      :is-disabled="isDisabled"
      type="number"
      :value="data.kolesterolTotal.answer"
      @input="setAnswer('kolesterolTotal', $event)"
    />
    <QShortAnswer
      v-if="data.kolesterolHdl"
      :question="data.kolesterolHdl.question"
      :is-disabled="isDisabled"
      type="number"
      :value="data.kolesterolHdl.answer"
      @input="setAnswer('kolesterolHdl', $event)"
    />
    <QShortAnswer
      v-if="data.kolesterolLdl"
      :question="data.kolesterolLdl.question"
      :is-disabled="isDisabled"
      type="number"
      :value="data.kolesterolLdl.answer"
      @input="setAnswer('kolesterolLdl', $event)"
    />
    <QShortAnswer
      v-if="data.asamUrat"
      :question="data.asamUrat.question"
      :is-disabled="isDisabled"
      type="number"
      :value="data.asamUrat.answer"
      @input="setAnswer('asamUrat', $event)"
    />
    <QShortAnswer
      v-if="data.lain"
      :question="data.lain.question"
      :is-disabled="isDisabled"
      :value="data.lain.answer"
      @input="setAnswer('lain', $event)"
    />

    <template v-for="item in unhandledQuestions">
      <pre :key="item.id">{{ item.id }} {{ item.question }}</pre>
    </template>
  </c-box>
</template>

<script >
import QShortAnswer from '@/components/quizionary/short-answer';
import { useEqualWatch, useQuestion, useSubmittedValue } from './helper-mixin';
import { computed } from '@vue/composition-api';
import _ from 'lodash';
const __sfc_main = {};
__sfc_main.props = ['value', 'disabled'];

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const questions = computed(() => props.value);
  const isDisabled = computed(() => props.disabled ?? false);
  const data = useQuestion(questions, {
    35: 'gulaDarahSewaktu',
    36: 'kolesterolTotal',
    37: 'kolesterolHdl',
    38: 'kolesterolLdl',
    39: 'asamUrat',
    40: 'lain',
    'de786572-ad1a-4d1e-afe1-ac5985300c9f': 'kolesterolTotal',
    'd85e6297-bdbb-4f1b-9375-90ac5bb01385': 'kolesterolHdl',
    '76de3969-0c45-4c0b-9bc2-9569817aa7b3': 'kolesterolLdl',
    '92a9d386-3c49-4d4e-bb95-8b07bafb1d89': 'asamUrat',
    '04958262-415f-4dae-a077-3c886a7bb6ac': 'lain'
  });
  const unhandledQuestions = computed(() => data.value.unhandledQuestions);
  let submittedValue = useSubmittedValue(data);
  useEqualWatch(submittedValue, data => {
    emit('input', data);
  });

  function setAnswer(name, value) {
    let val = _.cloneDeep(data.value);

    _.set(val, `${name}.answer`, value);

    data.value = val;
  }

  return {
    isDisabled,
    data,
    unhandledQuestions,
    setAnswer
  };
};

__sfc_main.components = Object.assign({
  QShortAnswer
}, __sfc_main.components);
export default __sfc_main;
</script>
