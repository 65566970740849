var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-form-control', {
    attrs: {
      "mb": "30px",
      "is-required": _vm.isRequired,
      "is-invalid": _vm.$v.value.$invalid
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" " + _vm._s(_vm.question) + " ")]), _c('c-input-group', {
    attrs: {
      "size": "lg"
    }
  }, [_vm._t("leftAddon"), _c('c-input', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':first-child': {
          roundedLeft: 'md'
        },
        ':last-child': {
          roundedRight: 'md'
        }
      },
      expression: "{\n        ':first-child': {\n          roundedLeft: 'md',\n        },\n        ':last-child': {\n          roundedRight: 'md',\n        },\n      }"
    }],
    attrs: {
      "placeholder": _vm.question,
      "type": _vm.formType,
      "value": _vm.value,
      "disabled": _vm.isDisabled,
      "rounded": "0"
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', $event);
      }
    }
  }), _vm._t("rightAddon")], 2), _c('c-form-helper-text', {
    attrs: {
      "font-size": "12px"
    }
  }, [_vm._v(" " + _vm._s(_vm.subtext) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }