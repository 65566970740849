import _ from 'lodash'

export function memoizeDebounce(func, wait = 0, options = {}) {
  const mem = _.memoize(function() {
    return _.debounce(func, wait, options)
  }, options.resolver)
  return function() {
    mem.apply(this, arguments).apply(this, arguments)
  }
}
